/*! ===================================
 *  Author: Nazarkin Roman, Egor Dankov
 *  -----------------------------------
 *  PuzzleThemes
 *  =================================== */

'use strict';

/***********************************************
 * Masonry Clients Layout
 ***********************************************/
(function ($) {
    var $grid = $('.sp-clients-grid'); // locate what we want to sort

    // don't run this function if this page does not contain required element
    if ($grid.length <= 0) {
        return;
    }

    // instantiate the plugin
    $grid.pzt_shuffle({
        itemSelector: '[class*="col-"]',
        gutterWidth : 0,
        speed       : 600, // transition/animation speed (milliseconds).
        easing      : 'ease'
    });
})(jQuery);